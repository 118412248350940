.social__fb:before {
  content: "";
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(/img/social.png);
  background-position: -14px -19px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .social__fb:before {
    background-image: url(/img/social@2x.png);
    background-size: 363px 74px;
  }
}

.social__vk:before {
  content: "";
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(/img/social.png);
  background-position: -74px -19px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .social__vk:before {
    background-image: url(/img/social@2x.png);
    background-size: 363px 74px;
  }
}

.social__mail:before {
  content: "";
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(/img/social.png);
  background-position: -254px -19px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .social__mail:before {
    background-image: url(/img/social@2x.png);
    background-size: 363px 74px;
  }
}

.social__gp:before {
  content: "";
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(/img/social.png);
  background-position: -194px -19px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .social__gp:before {
    background-image: url(/img/social@2x.png);
    background-size: 363px 74px;
  }
}

.social__tw:before {
  content: "";
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(/img/social.png);
  background-position: -134px -19px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .social__tw:before {
    background-image: url(/img/social@2x.png);
    background-size: 363px 74px;
  }
}

.social__ok:before {
  content: "";
  display: inline-block;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url(/img/social.png);
  background-position: -314px -19px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .social__ok:before {
    background-image: url(/img/social@2x.png);
    background-size: 363px 74px;
  }
}

/*# sourceMappingURL=social_icons.css.map */
