
/* Here starts the regular css */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline
}
body,
html {
    height: 100%
}
a img,
fieldset,
img {
    border: none
}
button::-moz-focus-inner,
input[type=submit]::-moz-focus-inner {
    padding: 0;
    border: 0
}
textarea {
    overflow: auto
}
.also__img,
.also__post,
.archive-years,
.article .article__author,
.article .article__author dd,
.article .article__content,
.article .article__header,
.article .article__header .author__pic,
.authors__list__item,
.banner__bottom,
.kitem .kitem__content,
.kitem .kitem__pic,
.main__img,
.month,
.page,
.paginate-block,
.paginate-block.centered,
.see__also,
.slider,
.slider__img,
.top #ticker ul li,
.vote .progress-bar {
    overflow: hidden
}
button,
input {
    margin: 0;
    padding: 0;
    border: 0
}
a,
a:focus,
button,
div,
h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
span,
textarea {
    outline: 0
}
a {
    color: #d88934
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    * {
        -webkit-text-size-adjust: none
    }
}
table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%
}
body {
    font-family: Tahoma, Arial, sans-serif;
    font-size: 12px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.footer .footer__in,
.header,
.nav .nav__mainin,
.nav_top .nav_top__wrap,
.nav__dropdown__wrap,
.page {
    width: 1030px;
    margin: 0 auto
}

.l .l-col,
.l .l-col1 {
    margin-right: 41px
}
.l .l-col1,
.l .l-col2,
.l .l-col3 {
    float: left;
    width: 316px
}
.l .l-col {
    float: left;
    width: 673px
}
.l .l-col3 {
    float: right;
    margin: 0
}

.hidden-scrollbar {
    overflow: hidden;
    
}

.no-scroll {
    position:fixed;
    overflow:hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.header {
    padding: 8px 0 20px;
    background: #ffffff;
}
.header .header__left {
    width: 30%;
    float: left;
    font-family: Georgia, serif;
    color: #9ba5ab;
    padding-top: 5px
}
.header .header__left .header__meta {
    margin-bottom: 12px
}
.header .header__left a {
    text-decoration: none;
    border-bottom: 1px dashed #7c8d99;
    color: #7c8d99
}
.header .header__left strong {
    color: #67747d
}

.header .header__right {
    width: 30%;
    float: right;
    text-align: right;
    font-family: Georgia, serif;
    padding-top: 4px
}
.h2,
.interesting__more,
.main__img .clip {
    text-align: center
}
.header .header__right a {
    text-decoration: none;
    border-bottom: 1px dashed #7c8d99;
    color: #7c8d99
}
.header .header__right .socials {
    list-style: none;
    font-size: 0
}
.header .header__right .socials li {
    display: inline-block;
    padding-bottom: 8px;
    border-bottom: 3px solid #fff;
    margin-left: 12px
}
.header .header__right .socials li:hover {
    border-color: #ffb71e
}
.header .header__right .socials a {
    display: inline-block;
    text-decoration: none;
    border: none;
    vertical-align: middle
}
.header .header__right .socials .socials__tw a {
    background: url(/img/icons.png) 0 -555px no-repeat;
    width: 16px;
    height: 13px
}
.header .header__right .socials .socials__vk a {
    background: url(/img/icons.png) 0 -425px no-repeat;
    width: 21px;
    height: 12px
}
.header .header__right .socials .socials__fb a {
    background: url(/img/icons.png) 0 -712px no-repeat;
    width: 8px;
    height: 16px
}
.header .header__right .socials .socials__gp a {
    background: url(/img/icons.png) 0 -496px no-repeat;
    width: 15px;
    height: 15px
}
.header .header__right .socials .socials__rss a {
    background: url(/img/icons.png) 0 -369px no-repeat;
    width: 14px;
    height: 14px
}
.header .header__right .socials .socials__tg a {
    background: url(/img/icons.png) 0 -926px no-repeat;
    width: 14px;
    height: 12px
}
.header .header__right .socials .socials__yt a {
    background: url(/img/icons.png) 0 -1012px no-repeat;
    width: 20px;
    height: 14px
}
.header .header__right .socials .socials__ins a {
    background: url(/img/icons.png) -69px -137px no-repeat;
    width: 15px;
    height: 14px
}

.header .header__logo {
    background: url(/img/icons.png) no-repeat;
    width: 275px;
    height: 127px;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    margin: 0 auto;
    display: block
}

.header .header__logo.header__logo-uk
{
	background: url(/img/logo-uka.png) no-repeat;
}

.header .header__logo__mobile {
    display: none;
}


.header .header__auth {
    position: absolute;
    top: 70px;
    font-size: 12px;
}

.header .header__auth span {
    color: #67747d;
    display: block;
    margin-bottom: 2px;
}

.header .header__auth a {
    margin-right: 5px;
    text-decoration: none;
}

.header .header__auth a:hover {
    text-decoration: underline;
}

.header .header_auth a:last-child {
    margin-right: 0px;
}

.mobile-soc__button
{
    display: none;
    position: absolute;
    top: 16px;
    right: 30px;
    cursor: pointer;
    width: 22px;
    height: 18px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAJqSURBVHjarNVPiJZVFAbw33wMWiKmUgmNGaJSUJgWFuhC00YMdFzmGFILKyJwIS4KtDYjWf4Dg8R/EKWOoIgbEckYBEHUGKpFSiC2GAiTmVRExzBq83xxefkmvsHvbM5z33tennvOPc+5be9v2KQFNgY9WItJuI4vsRnaW0TQj+eLb1NC2olFtRaQ9ITgLt7CZHyYvYXY2IpMuuPfxrHgr1BLybofNpPJmBrcV9n7Pn5mo0xqeAXP4kHqfXkEknEFnobBYv1M/B/VTD7AEM7jaxzEL7iCRZXY1/FTsd6H8cEdKRmcKDM5hWXBt/ADHsW8ZNWH9diJXqxK7M/J6GXcSPt2pHMHsLGeSW8IHuA9TMxJF2BCLhC24s+CYBtexKv4Do+kTO04ijm41Y6u4qfXcK5SlmGsy6k+zwH601W/JmYIS6OPp3GtvJ8a1gTvakBQ2hf4MfhAQVDa9ZR5sNpJ84OPN9Gy9Zj5o+nzWlKE35uIH4h/YrQkQ8GPNxH/ZPzN0ZJcCF7eRHxX/JnRkhwK/hgv/E/s2uIuhkeIeQzPFaL8j+QITmd9Dm80+PmjKLpu3+AknipG+9GU8TJuY380pi2P1piMkpfy0zWcjeI7MwhhS1S9Pev70c7KiLJqVzG7rvi/MhY+y3o63sGbIbiJ1SnpDszCJYzFJyEYyCHbsCTZzEBPW4Pnd0JGyiz8HXX34Z8GJ30Xe4MXV8Z9Nw7jt0aj/naTwqyLc28xKEurT4eOh320BjNKYHZlb079flvxxvfGf4u5wUuwJ/hIK974TViRS+6v7F3Ep63I5E66azfuFfe6pS7efwcAROqCtyQG8j4AAAAASUVORK5CYII=") center no-repeat;
    background-size: contain;
/*    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
    -webkit-transition: background-image 0.5s ease-in-out;
    transition: background-image 0.5s ease-in-out;*/
}

.mobile-soc__button.is-active
{
    background: url(" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZBAMAAAA2x5hQAAAAElBMVEX/sw3/3pVHcEz/y1n/ylf/sQUWDT3AAAAABXRSTlPTSgCHiRgxjmkAAABUSURBVBjTY1BCAgoM5PMMhMBMASMwT4ARxFFkEALzQDREDKwPxAALgXkgFlg5xEwBRohqCE+RwYERyT7RUCFcPFSVKKag2oBiO6rLUF2N6iOywgUAq9Ym1XkiUdEAAAAASUVORK5CYII=") center no-repeat;
/*    background-size: contain;*/
}

.mobile-menu__button {
    display: none;
    width: 18px;
    position: absolute;
    top: 17px;
    right: 0px;
    cursor: pointer;
}

.mobile-menu__button span {
    width: 18px;
    height: 2px;
    background-color: #67747c;
    margin-bottom: 5px;
    display: block;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
    border-radius: 2px;
}

.mobile-menu__button span:last-child {
    margin-bottom: 0px;
}

.mobile-menu__button.is-active span {
    background-color: rgba(255, 175, 0, 0.95);
}


.mobile-menu__button.is-active span.span__top {
    transform: translateY(7px) translateX(-3px) rotate(45deg);
    width: 23px;
}

.mobile-menu__button.is-active span.span__middle {
    opacity: 0;
}

.mobile-menu__button.is-active span.span__bottom {
    transform: translateY(-7px) translateX(-3px) rotate(-45deg);
    width: 23px;
}




.h2 span,
.nav .nav__main,
.nav_top {
    font-family: Georgia, serif;
}
.nav_top .nav_top__wrap 
{
    position: relative;
	display: flex;
	justify-content: space-between;
}
.nav_top {
    background: #67747d;
    height: 30px;
    line-height: 30px;
}
.nav_top .nav_top__right {
    /* position: absolute; */
    right: 0;
    top: 0;
    font-size: 12px;
    color: #b9c4cb;
}
.nav_top__langs_switch_item
{
	color: #fff;
	text-decoration: none;
	font-size: 14px;
	margin-right: 17px;
	position: relative;
	display: inline-block;
}
.nav_top__langs_switch_item:not(:last-child):after
{
	content: '';
	border-right: 1px solid #fff;
	height: 13px;
	position: absolute;
	top: 10px;
	display: block;
	right: -11px;
}
.nav_top .search {

}
.nav_top .search__input {
    display: inline-block;
    vertical-align: middle;
    background-image: url(/img/icon-search.svg);
    background-repeat: no-repeat;
    background-position: center right;
    background-color: transparent;
    border: 0px;
    outline: none;
    width: 20px;
    height: 20px;
    color: #ffffff;
    font-size: 0px;
    font-family: 'Tahoma', sans-serif;
    cursor: pointer;
    -webkit-transition: width .2s ease;
    -moz-transition: width .2s ease;
    -o-transition: width .2s ease;
    transition: width .2s ease;
    margin-top: -3px;
}

.nav_top .search__input::-webkit-input-placeholder {
  color: #ffffff;
}
.nav_top .search__input::-moz-placeholder {
  color: #ffffff;
}
.nav_top .search__input:-ms-input-placeholder {
  color: #ffffff;
}
.nav_top .search__input:-moz-placeholder {
  color: #ffffff;
}

.nav_top .search__input:focus {
    width: 100px;
    cursor: text;
    padding-right: 20px;
    font-size: 13px;
}

.nav_top__left-gradient,
.nav_top__right-gradient {
    position: absolute;
    top: 0px;
    height: 46px;
    width: 15px;
    display: none;
    z-index: 1;
}

.nav_top__left-gradient {
    left: 0px;
    background: url(/img/fade-left.svg) no-repeat center center;
}

.nav_top__right-gradient {
    right: 0px;
    background: url(/img/fade-right.svg) no-repeat center center;
}




.h2,
.h2 span {
    position: relative;
}
.nav_top .nav_top__right a {
    margin-left: 14px;
    font-size: 15px;
    color: #b9c4cb;
    text-decoration: none;
}

.nav_top .nav_top__special {
    cursor: default;
	text-align: center;
}

.nav_top .nav_top__special dt {
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    color: #ffaf00;
}
.nav_top .nav_top__special a,
.nav_top .nav_top__special dd,
.nav_top .nav_top__special li,
.nav_top .nav_top__special ul {
    display: inline-block;
    vertical-align: top;
}
.nav_top .nav_top__special a {
    margin-right: 16px;
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}

.nav_top .nav_top__special a.is-active {
    color: #ffb15e;
    text-decoration: underline;
}

.nav_top .nav_top__special .archive-link {
    display: none;
}

.nav .nav__main {
    background: #ededed;
    font-size: 16px;
    position: relative;
}

.nav .nav__main ul.nav__list {
    list-style: none;
    font-size: 0;
    float: left;
    position: relative;
    left: -13px;
}
.nav .nav__main ul.nav__list li {
    display: inline-block;
    vertical-align: top;
}
.nav .nav__main ul.nav__list a {
    display: inline-block;
    padding: 0 11px;
    height: 46px;
    line-height: 46px;
    color: #5f6c75;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 19px;
}
.nav .nav__main ul.nav__list a:hover {
    background: #dadbdd
}
.nav .nav__main ul.nav__list a.is-active {
    color: #fff;
    background: #67747d;
    box-shadow: inset 0 5px 0 #ffaf00;
}

.nav .nav__dropdown {
    float: right;
}

.nav .nav__dropdown .nav__dropdown__button {
    font-size: 15px;
    color: #5f6c75;
    display: inline-block;
    height: 46px;
    line-height: 46px;
    padding: 0px 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;
}

.nav .nav__dropdown .nav__dropdown__button:hover {
    background: #dadbdd;
}

.nav .nav__dropdown .nav__dropdown__button:after {
    content: url(/img/icon-dropdown-gray.svg);
    vertical-align: middle;
    margin-top: -5px;
    margin-left: 6px;
    display: inline-block;
    vertical-align: middle;
}

.nav .nav__dropdown .nav__dropdown__button.is-active {
    color: #fff;
    background: rgba(103, 116, 124, 0.98);
    box-shadow: inset 0 5px 0 #ffaf00;
}

.nav .nav__dropdown .nav__dropdown__button.is-active:hover {
    background: rgba(103, 116, 124, 0.98);
}

.nav .nav__dropdown .nav__dropdown__button.is-active:after {
    content: url(/img/icon-dropdown-white.svg);
}

.nav .nav__dropdown .nav__dropdown__content {
    cursor: default;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    background-color: rgba(103, 116, 124, 0.98);
    color: #ffffff;
    z-index: 101;
    padding: 30px 0px;
    -webkit-transition: 0.2s opacity;
    -moz-transition: 0.2s opacity;
    -ms-transition: 0.2s opacity;
    -o-transition: 0.2s opacity;
    transition: 0.2s opacity;
    overflow: hidden;
    display: none;
    opacity: 0;
}

.nav .nav__dropdown .nav__dropdown__content.is-active {
    opacity: 1;
}


.nav .nav__dropdown .nav__dropdown__menu,
.mobile-menu .mobile-menu__section {
    display: inline-block;
    margin-right: 90px;
    vertical-align: top;
}

.mobile-menu .mobile-menu__section {
    margin-right: 50px;
    margin-bottom: 30px;
}

.mobile-menu .mobile-menu__section:last-child {
    margin-bottom: 0px;
}

.nav .nav__dropdown .nav__dropdown__menu h4,
.mobile-menu .mobile-menu__section h4 {
    font-size: 19px;
    color: #ffffff;
    font-family: Georgia, serif;
    text-transform: uppercase;
    margin: 30px 0px 0px 0px;
    font-weight: 400;
}

.nav .nav__dropdown .nav__dropdown__menu h4 a,
.mobile-menu .mobile-menu__section h4 a {
    color: #ffffff;
    text-decoration: none;
}

.nav .nav__dropdown .nav__dropdown__menu h4 a:hover,
.mobile-menu .mobile-menu__section h4 a:hover {
    color: rgb(254, 175, 3);
}

.nav .nav__dropdown .nav__dropdown__menu h4:first-child,
.mobile-menu .mobile-menu__section h4:first-child {
    margin-top: 0px;
}

.nav .nav__dropdown .nav__dropdown__menu ul,
.mobile-menu .mobile-menu__section ul {
    margin: 15px 0px 0px 0px;
    padding: 0;
}

.nav .nav__dropdown .nav__dropdown__menu ul li,
.mobile-menu .mobile-menu__section ul li {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.75);
    line-height: 1.2;
    margin: 0px 0px 8px 0px;
    font-family: 'Tahoma', sans-serif;
}

.mobile-menu .mobile-menu__section ul li {
    margin: 0px 0px 20px 0px;
}

.nav .nav__dropdown .nav__dropdown__menu ul li:last-child,
.mobile-menu .mobile-menu__section ul li:last-child {
    margin-bottom: 0px;
}

.nav .nav__dropdown .nav__dropdown__menu ul li a,
.mobile-menu .mobile-menu__section ul li a {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.75);
    text-decoration: none;
    font-family: 'Tahoma', sans-serif;
}

.nav .nav__dropdown .nav__dropdown__menu ul li a:hover,
.mobile-menu .mobile-menu__section ul li a:hover {
    color: #ffffff;
}

.nav .nav__dropdown .nav__dropdown__menu .double-ul ul {
    display: inline-block;
    margin-right: 60px;
    vertical-align: top;
}

.nav .nav__dropdown .nav__dropdown__menu .double-ul ul:last-child {
    margin-right: 0px;
}

.mobile_soc
{
    position: absolute;
    top: 96px;
    left: 0;
    right: 0;
    overflow: hidden;
    padding: 10px;
    text-align: center;
    background: #67747d;
    border-bottom: 1px solid #ffaf00;
    box-shadow: 0px 10px 10px 5px #c4c4c4;
    z-index: 201;
    opacity: 0;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
    display: none;
}

.mobile_soc.is-active {
    opacity: 1;
    display: block;
}

@media only screen and (min-width: 801px) {
    .mobile_soc.is-active {
        display: none;
    }
}

.mobile-menu {
    position: fixed;
    overflow: hidden;
    top: 96px;
    left: 0px;
    right: 0px;
    z-index: 200;
    background-color: rgba(103, 116, 124, 1);
    height: 0vh;
    opacity: 0;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
    pointer-events: none;
}

.mobile-menu.is-active {
    height: calc(100vh - 96px);
    opacity: 1;
    pointer-events: auto;
}

@media only screen and (min-width: 801px) {
    .mobile-menu.is-active {
        display: none;
    }
}

.mobile-menu__search {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    position: relative;
    z-index: 202;
}

.mobile-menu__search input {
    display: block;
    padding: 20px 20px 18px 0px;
    color: #ffffff;
    background-image: url(/img/icon-search.svg);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 14px 14px;
    font-family: Tahoma, sans-serif;
    font-size: 16px;
    width: 95%;
    margin: 0px auto;
    line-height: 1.21;
    background-color: transparent;
    border: 0;
    outline: none;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}

.mobile-menu__search input::-webkit-input-placeholder {
  color: #ffffff;
}
.mobile-menu__search input::-moz-placeholder {
  color: #ffffff;
}
.mobile-menu__search input:-ms-input-placeholder {
  color: #ffffff;
}
.mobile-menu__search input:-moz-placeholder {
  color: #ffffff;
}

.mobile-menu__content {
    padding: 0px 0px 0px 0px;
    width: 95%;
    position: relative;
    margin: 0px auto;
    overflow: hidden;
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;
    box-sizing: border-box; 
}

.mobile-menu__content__wrap {
    width: 100%;
    height: calc(100vh - 341px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 20px 0px 170px 0px;
}

.mobile-menu__content__wrap::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

.mobile-menu__gradient-top,
.mobile-menu__gradient-bottom {
    position: fixed;
    left: 0px;
    right: 0px;
    height: 20px;
    z-index: 201;
}

.mobile-menu__gradient-top {
    top: 151px;
    background: url(/img/fade-menu-top.svg) repeat-x top center;
}

.mobile-menu__gradient-bottom {
    display: none;
    bottom: 0px;
    background: url(/img/fade-menu-bottom.svg) repeat-x bottom center;
}

.mobile-menu__gradient-bottom.is-active {
    display: block;
}


.footer {
    background: #67747d;
}
.footer .footer__in {
    padding-top: 17px;
}
.footer .footer__in:after {
    display: table;
    clear: both;
}
.footer .footer__gp,
.footer .footer__nav li {
    display: inline-block;
}
.footer .footer__left {
    width: 657px;
    float: left;
    margin-left: 15px
}
.footer .footer__right {
    width: 316px;
    float: right;
    margin-right: 15px
}
.footer .footer__counters {
    padding-bottom: 24px;
    padding-top: 10px
}
.footer .footer__nav {
    clear: both;
    list-style: none;
    border-bottom: 1px solid #8a959d;
    padding: 0 0 13px 0;
    margin: 0 15px 15px 15px;
}
.footer .footer__nav a {
    font-size: 13px;
    text-decoration: none;
    color: #fff;
    display: inline-block;
    margin-right: 45px
}
.footer .footer__gp {
    vertical-align: middle;
    font-size: 13px;
    color: #ffbb23;
    font-weight: 700;
    margin-left: 10px;
    text-decoration: none
}
.footer .footer__director {
    font-size: 15px;
    color: #fff
}
.footer .footer__director dt {
    font-size: 12px;
    color: #b4bdc2;
    margin-bottom: 4px;
    margin-top: 14px
}
.footer .footer__director dt:first-child {
    margin-top: 0
}
.footer .footer__director dt a {
    color: #fff
}
.footer .footer__director dd {
    font-size: 15px;
    color: #fff
}
.footer .footer__director dd a,
.footer .footer__director dd strong a {
    text-decoration: none;
    color: #fff
}
.footer .footer__director dd strong {
    display: block
}
.comments,
.footer .socials,
.footer .socials a,
.footer .socials li,
.mobile_soc .socials,
.mobile_soc .socials a,
.mobile_soc .socials li,
.h2 span,
.views {
    display: inline-block
}
.footer .socials {
    vertical-align: middle;
    font-size: 0;
    margin-bottom: 12px
}
.footer .socials a {
    margin-right: 10px;
    text-decoration: none;
    vertical-align: middle
}
.footer .socials .socials__tw a,
.mobile_soc .socials .socials__tw a {
    background: url(/img/icons.png) 0 -239px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__tw a:hover,
.mobile_soc .socials .socials__tw a:hover {
    background: url(/img/icons.png) 0 -612px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__vk a,
.mobile_soc .socials .socials__vk a {
    background: url(/img/icons.png) 0 -335px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__vk a:hover,
.mobile_soc .socials .socials__vk a:hover {
    background: url(/img/icons.png) 0 -521px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__fb a,
.mobile_soc .socials .socials__fb a {
    background: url(/img/icons.png) 0 -578px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__fb a:hover,
.mobile_soc .socials .socials__fb a:hover {
    background: url(/img/icons.png) 0 -738px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__gp a,
.mobile_soc .socials .socials__gp a {
    background: url(/img/icons.png) 0 -137px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__gp a:hover,
.mobile_soc .socials .socials__gp a:hover {
    background: url(/img/icons.png) 0 -301px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__rss a,
.mobile_soc .socials .socials__rss a {
    background: url(/img/icons.png) 0 -171px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__rss a:hover,
.mobile_soc .socials .socials__rss a:hover {
    background: url(/img/icons.png) 0 -205px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__tg a,
.mobile_soc .socials .socials__tg a {
    background: url(/img/icons.png) 0 -948px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__tg a:hover,
.mobile_soc .socials .socials__tg a:hover {
    background: url(/img/icons.png) 0 -982px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__yt a,
.mobile_soc .socials .socials__yt a {
    background: url(/img/icons.png) 0 -1032px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__yt a:hover,
.mobile_soc .socials .socials__yt a:hover {
    background: url(/img/icons.png) 0 -1063px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__ins a,
.mobile_soc .socials .socials__ins a {
    background: url(/img/icons.png) -69px -157px no-repeat;
    width: 24px;
    height: 24px
}
.footer .socials .socials__ins a:hover,
.mobile_soc .socials .socials__ins a:hover {
    background: url(/img/icons.png) -69px -187px no-repeat;
    width: 24px;
    height: 24px
}


.footer .footer__copy {
    color: #fff;
    font-size: 15px;
    margin-bottom: 10px
}
.footer .footer__text {
    font-size: 12px;
    color: #b4bcc2;
    margin-bottom: 10px
}
.footer .footer__text p {
    margin-bottom: 6px
}
.footer .footer__text a {
    text-decoration: none;
    color: #fff
}
.page {
    padding: 20px 5px 0;
    background-color: white;
    position: relative
}
.h2 {
    border-top: 2px solid #dadbdd;
    font-weight: 400;
    margin-top: 21px
}
.h2 span {
    height: 28px;
    line-height: 28px;
    padding: 0 25px;
    border-top: 4px solid #efac1c;
    color: #fff;
    background: #637079;
    font-size: 16px;
    top: -18px
}
.h2 span a {
    color: #fff;
    text-decoration: none
}
.comments,
.meta,
.views {
    color: #a2a2a2;
    font-size: 13px
}
.h2:after,
.h2:before {
    position: absolute;
    height: 16px;
    width: 2px;
    background: #dadbdd;
    top: -8px;
    left: 0
}
.h2:after {
    right: 0;
    left: auto
}
.meta {
    margin: 5px 0
}
.comments,
.views {
    text-decoration: none;
    /*margin-left: 10px*/
}
.comments:before,
.views:before {
    content: " ";
    display: inline-block;
    background: url(/img/icons.png) 0 -646px no-repeat;
    width: 16px;
    height: 10px;
    margin-right: 4px
}
.comments:before {
    background: url(/img/icons.png) 0 -833px no-repeat;
    width: 10px;
    height: 10px
}
.mat {
    margin-bottom: 20px
}
.mat .mat__media {
    margin-bottom: 10px;
    position: relative
}
.mat .mat__media img {
    /*width: 100%;*/
    display: block;
    margin: 0 auto;
}
.mat .mat__comments {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #373737;
    background: #ffb71e
}
.mat .mat__title,
.mat .mat__title a {
    color: #343739;
    text-decoration: none
}
.mat .mat__type {
    position: absolute;
    right: 0;
    top: 0;
    width: 46px;
    height: 36px;
    background: #ffaf00
}
.mat .mat__type:before {
    position: absolute;
    display: inline-block
}
.mat .mat__type.is-audio:before {
    background: url(/img/icons.png) 0 -393px no-repeat;
    width: 18px;
    height: 22px;
    left: 15px;
    top: 7px
}
.mat .mat__type.is-video:before {
    background: url(/img/icons.png) 0 -273px no-repeat;
    width: 24px;
    height: 18px;
    left: 11px;
    top: 8px
}
.mat .mat__type.is-text:before {
    background: url(/img/icons.png) 0 -772px no-repeat;
    width: 17px;
    height: 21px;
    left: 16px;
    top: 7px
}
.mat .mat__type.is-photo:before {
    background: url(/img/icons.png) 0 -447px no-repeat;
    width: 21px;
    height: 16px;
    left: 13px;
    top: 9px
}
.mat .mat__title {
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px
}
.mat .mat__meta {
    margin-bottom: 7px;
    margin-top: 3px
}
.mat .mat__meta .mat__published {
    display: inline-block;
    color: #a2a2a2;
    font-size: 13px
}
.mat .mat__text {
    font-size: 14px;
    line-height: 1.3;
    color: #343739
}
.main__img {
    position: relative;
    /*width: 316px;*/
    margin: 0 auto;
}
.main__img .clip {
    position: absolute;
    height: 316px;
    width: 500px;
    left: -25%
}
.interesting__more a {
    height: 46px;
    background-color: #f7b719;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
    line-height: 46px;
    padding: 0 23px;
    color: #37393b;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none
}
.articles,
.authors,
.banner,
.hbnhab, /* duplicate of .banner */
.digest,
.fotovideo,
.fraza,
.interview,
.kolonka,
.mainnews,
.news,
.vote {
    margin-bottom: 10px
}
.banner, .hbnhab {
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.banner__exclusive {
    height:250px;
    width:300px;
    padding: 2px 2px 8px 2px;
    background-color: #dadbdd;
    background-image: url(/img/reklama.jpg);
    background-repeat: no-repeat;
    background-position: bottom right;
}
ins {
    padding: 2px 2px 8px 2px;
    border: 1px solid #dadbdd;
    /*background-image: url(/img/reklama.jpg);*/
    background-repeat: no-repeat;
    background-position: bottom right;
    display: block;
}
.n {
    display: block;
    margin: 0 -10px;
    text-decoration: none;
    padding: 5px 10px
}
.n .n__photo,
.n .n__video {
    display: inline-block;
    vertical-align: middle;
    top: -2px;
    position: relative
}
.n.n_important {
    background: #f0f0f0
}
.n.n_important .n__title {
    font-weight: 700
}
.n .n__author,
.n .n__published {
    color: #a1a1a1;
    font-size: 13px;
    font-weight: 400
}
.n .n__title {
    color: #37393b;
    text-decoration: none;
    font-size: 13px
}
.n .n__photo {
    background: url(/img/icons.png) 0 -666px no-repeat;
    width: 16px;
    height: 12px;
    margin-left: 10px
}
.n .n__video {
    background: url(/img/icons.png) 0 -473px no-repeat;
    width: 18px;
    height: 13px;
    margin-left: 10px
}
.one_item_block .n__author,
.one_item_block .n__published {
    color: #a1a1a1;
    font-size: 14px;
    font-weight: 400
}
.one_item_block .n__author a {
    text-decoration: none
}
.one_item_block .n__title {
    color: #37393b;
    text-decoration: none;
    font-size: 14px
}
.one_item_block .n__title a {
    color: #000;
    text-decoration: none;
    font-weight: 700
}
.slider-placeholder {
    width: 100%;
    height: 150px;
    text-align: center;
    padding-bottom: 166px
}
.slider {
    display: none
}
.slider__bottom {
    height: 17px;
    line-height: 17px;
    background: #ffb71e
}
.slider__bottom .slider__copy {
    float: left;
    font-size: 10px;
    font-weight: 700;
    color: #4b575f;
    margin-left: 10px
}
.slider__bottom .slider__nav {
    float: right;
    text-align: right;
    margin-right: 14px;
    padding-top: 5px
}
.also__img .clip,
.top .top__label,
.vote {
    text-align: center
}
.slider__bottom .slider__nav div {
    display: inline-block;
    margin-left: 12px;
    cursor: pointer;
    vertical-align: top
}
.slider__bottom .slider__nav .slider__next {
    background: url(/img/icons.png) 0 -871px no-repeat;
    width: 33px;
    height: 8px
}
.slider__bottom .slider__nav .slider__prev {
    background: url(/img/icons.png) 0 -853px no-repeat;
    width: 33px;
    height: 8px
}
.slider__slide {
    position: relative;
    width: 100%
}
.slider__slide .slider__title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 7px 15px;
    font-size: 14px;
    color: #fff;
    background: rgba(0, 0, 0, .6);
    text-decoration: none
}
.slider__slide .slider__title.bc
{
    font-size: 16px;
    font-weight: bold;
}
.slider__img {
    position: relative;
    width: 316px;
    height: 316px;
    margin: 0 auto;
}
.slider__img .clip {
    position: absolute;
    height: 316px;
    width: 500px;
    /*left: -25%;*/
    left: -30%;
    text-align: center
}
.slider__img img {
    min-height: 316px;
    min-width: 316px;
    width: auto;
    height: auto
}
.article {
    margin-bottom: 17px
}
.article .article__print a,
.article .article__published {
    font-size: 13px;
    color: #a2a2a2;
    margin-top: 3px;
    line-height: 1
}
.article:after {
    display: table;
    clear: both
}
.article .article__img {
    float: left;
    margin-right: 17px
}
.article .article__img img {
    width: 140px;
    height: 105px
}
.article .article__print a {
    margin-left: 10px
}
a.photobox img {
    height: auto!important
}
.article .article__summary img {
    max-width: 100%
}
.scaledvideo-container {
	position:relative;
	padding-bottom:56.25%;
	/*padding-top:30px;*/
	height:0;
	overflow:hidden;
	width: 100%;
	margin-top: 15px;
	margin-bottom: 25px;
}
.scaledvideo-container .scaledvideo {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
.article .article__categ.hot {
    background: none;
    -webkit-box-shadow: inset 0 0 0 10px #ffb71e;
    -moz-box-shadow: inset 0 0 0 10px red;
    box-shadow: inset 0 0 0 1px #ffb71e
}
.header:after,
.l:after {
    display: table;
    content: " ";
    clear: both
}
.article:after,
.author:hover:before,
.footer .footer__in:after,
.fraza blockquote:before,
.h2:after,
.h2:before,
.header:after,
.kitem:after,
.l:after,
.mat .mat__type:before {
    content: " "
}

.article .article__categ {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 10px;
    color: #343739;
    background: #ffb71e;
    padding: 3px 10px;
    margin-bottom: 6px;
    line-height: 1;
    font-weight: 700;
    display: inline-block;
}
.article .article__title__big {
    font-size: 26px !important;
}
.article .article__title {
    font-size: 15px;
    font-weight: 700;
    color: #343739;
    line-height: 1.1;
    margin-bottom: 7px;
}
.article .article__title a {
    text-decoration: none;
    color: #383a3c
}
.article .article__author {
    margin-bottom: 6px
}
.article .article__author dt {
    float: left;
    color: #343739;
    font-size: 13px;
    margin-right: 5px
}
.article .article__author a {
    text-decoration: none
}
.article .article__summary {
    max-width: 100%;
    font-size: 14px;
    color: #343739;
    line-height: 1.3;
    float: left;
    margin-top: 10px;
    width: 100%;
    /*letter-spacing: 0.4px;*/
}
.article .article__summary h1,
.article .article__summary h2,
.article .article__summary h3,
.article .article__summary h4,
.article .article__summary h5
{
    margin-bottom: 10px;
}
.article .article__summary a {
    text-decoration: none
}
.article .article__summary a:hover {
    text-decoration: underline;
}
.article .article__summary .article__img__big {
    margin: 10px;
    float: left;
    position: relative
}
.article .article__summary .article__img__big img {
    margin: 0 10px 10px 0;
    width: 320px
}
.article .article__summary p {
    margin-bottom: 15px
}
.article .article__content {
    float: left;
    font-size: 13px;
    width: 100%
}
.article .article__header {
    float: left;
    width: 100%;
}
.article .article__header .author__pic {
    float: left;
    width: 80px;
    /*width: 20%;*/

}
.article .article__header .author__pic img {
    max-width: 70px;
    max-height: 50px;
}
.article .article__header .article__meta {
    /*float: right;*/
    /*width: 75%*/
    margin-left: 6px;
}
.article .article__header .article__meta .article__author a {
    text-decoration: none;
    font-weight: 700
}
.article__stat div {
    float: left;
    margin-right: 10px;
}
.article__stat .article__print {
    margin: 2px 0 0 0;
}
.share {
    float: right !important;
    /*margin-bottom: 10px;*/
}
.share div {
    float: left;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    color: #5f6c75;
    cursor: pointer;
}


.spec__top {
    float: right;
    min-height: 125px;
    width: 500px;
    border-bottom: 1px solid #cccdd1
}
.l-col2 .article__img {
    float: left
}
.l-col2 .article img {
    float: left;
    margin-right: 10px
}
.l-col2 .article .author a {
    color: #f5a615;
    text-decoration: none
}
.l-col2 .article,
.l-col2 .article a,
.l-col2 .article p {
/*    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto*/
}
.spec__title {
    font-size: 15px;
    font-family: Georgia;
    font-weight: 700;
    color: #343739;
    line-height: 1.1;
    margin-bottom: 7px
}
.also__row,
.banner__bottom,
.top {
    margin-bottom: 20px
}
.spec__title a {
    text-decoration: none;
    color: #383a3c
}
.also__post a,
.top #ticker ul li a {
    color: #000;
    text-decoration: none
}
.top {
    width: 100%;
    height: 25px;
    display: none;
    float: left;
    background-color: #ededed
}
.top .top__label {
    float: left;
    background-color: #fcb133;
    font-weight: 700;
    padding: 4px;
    width: 40px;
    position: absolute
}
.top #ticker {
    width: 100%;
	max-height: 30px;
}
.top #ticker ul
{
    position: absolute;
    left: 55px;
    right: 0px;
}
.top #ticker ul li {
    width: 100%;
    height: 17px;
    padding: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.top #ticker .top__date {
    font-weight: 700
}
.top-tags
{
	display: flex;
}
.top-tags-label
{
	float: left;
	/* background-color: #fcb133; */
	font-weight: 700;
	padding: 4px;
	width: 75px;
}
.top-tags-container
{
	margin-left: 10px;
	display: flex;
	border-left: 1px dashed #d7d7d7;
}
.top-tags-container ul
{
	display: flex;
	flex-wrap: wrap;
}

.top-tags-container ul > li
{
	padding: 0 10px;
	font-size: 14px;
}
.breadcrumbs
{
	padding: 10px 7px 15px;
}
.breadcrumbs,
.breadcrumbs a
{
	color: gray;
}
.breadcrumbs a:last-of-type
{
	color: #a4a4a4;
	text-decoration: none;
}
.breadcrumbs .bc-separator
{
	
}
/*.see__also {
    float: left
}*/
.also__row {
    float: left;
    width: 100%;
    overflow: hidden;
    clear: both;
}
.also__post {
    float: left;
    width: 30%;
    padding: 10px
}
.also__post a {
    font-weight: 700
}
.also__img {
    position: relative;
    width: 200px;
    height: 200px
}
.also__img .clip {
    position: absolute;
    height: 200px;
    width: 500px;
    left: -75%
}
.also__img img {
    min-height: 200px;
    min-width: 200px;
    width: auto;
    height: auto
}
.tagscloud a
{
    text-decoration: none;
}
.authors__list__item,
.banner__bottom,
.banners__bottom {
    width: 100%;
    float: left
}
.authors__list__item {
    margin-bottom: 20px
}
.authors__list__item .author__pic {
    float: left;
    width: 164px;
    padding-bottom: 50px
}
.authors__list__item .author__info {
    float: right;
    width: 509px;
    font-size: 14px
}
.authors__list__item .author__info h3 a {
    color: #383a3c;
    text-decoration: none
}
.authors__list__item .author__info a {
    color: #f2a518
}
.authors__list__item .author__info .author__wrote,
.authors__list__item .author__info .work {
    color: #797878
}
.authors__list__item .author__info .author__wrote a {
    color: #f2a518;
    font-weight: 700;
    text-decoration: none
}
.list {
    margin-bottom: 30px
}
.list .h3,
.list h3 {
    font-size: 13px;
    font-weight: 700;
    color: #343739;
    margin-bottom: 5px
}
.list ul {
    list-style: none
}
.list li {
    margin-bottom: 4px;
    line-height: 1.1
}
.list a {
    color: #757a7d;
    text-decoration: none;
    font-size: 13px
}
.list a:hover {
    color: #f2a518
}
.authors {
    font-size: 0
}
.author {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 18px;
    position: relative;
    text-decoration: none
}
.author:hover:before {
    position: absolute;
    left: -6px;
    bottom: -3px;
    top: -6px;
    right: 6px;
    background: #f0f0f0
}
.author>* {
    position: relative;
    z-index: 1
}
.author .author__pic {
    float: left;
    margin-right: 13px
}
.author h3 {
    font-weight: 700;
    font-size: 13px;
    color: #343739;
    line-height: 1.1;
    margin-bottom: 3px;
    margin-top: -3px
}
.author h3 a {
    text-decoration: none;
    color: #343739
}
.author .author__type,
.author .author__wrote {
    margin-bottom: 1px;
    font-size: 11px;
    color: #7a7878
}
.author .author__type a,
.author .author__wrote a {
    font-weight: 700;
    text-decoration: none;
    color: #f2a618
}
.kitem {
    font-size: 13px;
    color: #343739
}
.kitem:after {
    display: table;
    clear: both
}
.kitem .kitem__pic {
    float: left;
    margin-right: 17px;
    width: 100px;
    height: 100px;
    position: relative
}
.kitem .kitem__pic img {
    position: absolute
}
.kitem .kitem__published {
    line-height: 1;
    font-size: 13px;
    color: #a2a2a2;
    margin-bottom: 8px
}
.kitem .kitem__title {
    font-size: 20px;
    font-weight: 400;
    font-family: Georgia, serif;
    color: #343739;
    line-height: 1.1;
    text-decoration: none;
    margin-bottom: 7px
}
.kitem .kitem__title a {
    text-decoration: none;
    color: #343739
}
.vote .vote__title {
    font-size: 14px;
    margin: 0 33px 10px 25px;
    color: #343739;
    font-weight: 700;
    padding-bottom: 4px;
    border-bottom: 1px solid #cdced2;
    text-align: left
}
.vote .vote__options {
    text-align: left;
    margin-left: 25px;
}
.vote .vote__options li {
    font-size: 13px;
    color: #343739;
    margin-bottom: 10px
}
.vote .vote__options li .voted {
    width: 70px;
    font-weight: 700;
    float: left
}
.vote .vote__options li label {
    padding-left: 25px;
    position: relative;
    display: block;
    line-height: 1.15
}
.vote .vote__options li input {
    position: absolute;
    left: 0;
    top: 2px
}
.vote .vote__submit {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    color: #37393b;
    font-size: 15px;
    padding: 0 17px;
    margin: 10px 0;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #f7b719;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px
}
.vote .vote__results {
    text-align: center;
    line-height: 1
}
.vote .vote__results a {
    font-size: 13px;
    color: #343739
}
.vote .vote__results__list {
    text-align: left;
    margin-left: 25px
}
.vote .vote__results__list li {
    font-size: 13px;
    color: #343739;
    margin-bottom: 10px
}
.vote .vote__results__list li .voted {
    width: 70px;
    font-weight: 700;
    float: left
}
.vote .progress-bar {
    width: 100%;
    background-color: #f5f5f5;
    height: 20px;
    margin: 10px 0
}
.vote .progress-bar .percent {
    background-color: #6a737c;
    color: #000;
    position: relative;
    float: left;
    font-size: 10px
}
.vote .progress-bar .percent span {
    float: right;
    background-color: #f8b71a;
    padding: 3px 5px
}
.fraza {
    text-align: center
}
.fraza .fraza__avatar {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background-position: center center;
    background-size: auto 100%
}
.fraza .fraza__avatar.lazyload {
    width: 1px;
    height: 1px;
}
.fraza .fraza__author a {
    color: #343739;
    font-weight: 700;
    text-decoration: none;
}
.fraza .fraza__author {
    margin: 4px 0 10px;
    font-size: 13px;
}
.fraza blockquote {
    font-size: 15px;
    font-family: Georgia, serif;
    line-height: 1.24;
    color: #343739;
    padding-left: 30px;
    position: relative;
    text-align: left
}
.archive-years h1,
.center,
.month .day,
.month .name {
    text-align: center
}
.fraza blockquote:before {
    position: absolute;
    left: 0;
    top: 0;
    background: url(/img/icons.png) 0 -688px no-repeat;
    width: 18px;
    height: 14px
}
.articles .search {
    border-left: 3px solid #dee5ea;
    padding: 3px 0 3px 13px
}
.articles .search .search__input {
    font-weight: 700;
    font-size: 12px;
    color: #82939e;
    width: 200px;
    border: none;
    box-shadow: none;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}
.archive-years .year a:hover,
.month .day a:hover {
    border-bottom: 2px solid #d9dadc
}
.articles .search .search__input:focus {
    width: 100px
}
.articles .search .search__button {
    background: url(/img/icons.png) 0 -889px no-repeat;
    width: 24px;
    height: 6px;
    display: inline-block;
    vertical-align: middle
}
.articles .tagdesc
{
    margin-bottom: 20px;
    padding: 10px 15px;
    border-bottom: 1px solid #dadbdd;
    font-size: 12px;
    text-align: justify;
    background: #f7f7f7;
}
.articles .tagdesc img
{
    width: 100px;
    float: left;
    margin: 5px 15px 3px 0;
    border-top: 3px solid #d88934;
}

.search-query {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px
}
.archive-years {
    width: 100%;
    clear: both;
    float: left
}
.archive-years h1 {
    float: left;
    width: 100%;
    font-family: Georgia
}
.archive__navigation {
    width: 500px;
    margin: 0 auto
}
.counters,
.month {
    margin-top: 20px
}
.archive__left__arrow {
    float: left;
    background: url(/img/icons.png) 0 -845px no-repeat;
    width: 50px;
    height: 24px
}
.archive__right__arrow {
    float: left;
    background: url(/img/icons.png) 0 -862px no-repeat;
    width: 50px;
    height: 24px
}
.archive-years .year {
    width: 50px;
    padding: 5px;
    float: left;
    text-align: center
}
.archive-years .activeYear {
    font-weight: 700;
    background-color: #f8b71a;
    border-radius: 30px
}
.archive-years a {
    color: #000;
    text-decoration: none
}
.archive-years .active a {
    color: #fff
}
.month {
    width: 30%;
    float: left;
    margin-right: 2%;
    height: 230px
}
.month .name {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f7b719;
    color: #383a3c;
    font-weight: 700
}
.month .day {
    width: 14.27%;
    height: 10px;
    float: left;
    background-color: #fff;
    padding: 10px 0
}
.counters {
    width: 100%;
    padding-bottom: 15px;
    float: left
}
.month .day a {
    text-decoration: none;
    color: #383a3c
}
.month .week {
    background-color: #ededed;
    color: #383a3c
}
.counter {
    float: left;
    margin-right: 10px
}
#vk_like {
    width: 155px!important
}
#twitter iframe {
    width: 95px!important
}
.gray {
    color: #b7b8b9
}
.noborder {
    border: none
}
.expand {
    width: 100%!important
}
.paginate-block {
    clear: both;
    width: 100%;
    position: relative
}
.paginate-block.centered {
    width: 70%;
    margin: 0 auto
}
.paginate-block .show-all {
    color: #ffb71e!important;
    display: inline-block;
    float: left;
    width: 200px;
    margin-left: 110px;
    margin-top: 3px;
    position: absolute
}
.jPaginate {
    height: 30px;
    position: relative;
    color: #ffb71e;
    font-size: small;
    float: left
}
.jPaginate a,
span.jPag-current {
    line-height: 15px;
    height: 18px;
    float: left
}
.jPaginate a {
    padding: 2px 5px;
    margin: 2px
}
.jPag-control-back {
    position: absolute;
    left: 0
}
.jPag-control-front {
    position: absolute;
    top: 0;
    width: 110px
}
.author,
.jPaginate a,
.jPaginate span,
.vote .vote__options li label,
.vote .vote__submit,
button,
input[type=submit] {
    cursor: pointer
}
ol, ul
{
	padding-left: 10px;
	margin-left: 10px;
}
.top-tags-list, .vote__options, .vote__results__list
{
	list-style-type: none
}
ul.jPag-pages {
    list-style-type: none
}
ul.jPag-pages {
    float: left;
    margin: 0 0 0 10px;
    padding: 0
}
ul.jPag-pages li {
    display: inline;
    float: left;
    padding: 0;
    margin: 0
}
ul.jPag-pages li a {
    float: left;
    padding: 2px 7px
}
span.jPag-current {
    cursor: default;
    font-weight: 400;
    padding: 2px 5px;
    margin: 2px
}
.foto,
.video,
.blog {
    display: inline-block;
    width: 18px;
    height: 14px;
    /*padding-left: 25px*/
}
span.jPag-snext,
span.jPag-snext-img,
span.jPag-sprevious,
span.jPag-sprevious-img,
ul.jPag-pages li span.jPag-next,
ul.jPag-pages li span.jPag-next-img,
ul.jPag-pages li span.jPag-previous,
ul.jPag-pages li span.jPag-previous-img {
    height: 22px;
    float: left;
    line-height: 18px
}
ul.jPag-pages li span.jPag-previous,
ul.jPag-pages li span.jPag-previous-img {
    margin: 2px 0 2px 2px;
    font-size: 12px;
    font-weight: 700;
    width: 10px
}
ul.jPag-pages li span.jPag-next,
ul.jPag-pages li span.jPag-next-img {
    margin: 2px 2px 2px 0;
    font-size: 12px;
    font-weight: 700;
    width: 10px
}
span.jPag-sprevious,
span.jPag-sprevious-img {
    margin: 2px 0 2px 2px;
    font-size: 18px;
    width: 15px;
    text-align: right
}
span.jPag-snext,
span.jPag-snext-img {
    margin: 2px 2px 2px 0;
    font-size: 18px;
    width: 15px;
    text-align: right
}
ul.jPag-pages li span.jPag-previous-img {
    background: url(/img/jpaginate/previous.png) center right no-repeat
}
ul.jPag-pages li span.jPag-next-img {
    background: url(/img/jpaginate/next.png) center left no-repeat
}
span.jPag-sprevious-img {
    background: url(/img/jpaginate/sprevious.png) center right no-repeat
}
span.jPag-snext-img {
    background: url(/img/jpaginate/snext.png) center left no-repeat
}
span.e-mail:before {
    content: attr(data-user) "\0040" attr(data-domain);
    unicode-bidi: bidi-override
}
.normal {
    font-weight: 400
}
.bold {
    font-weight: 700
}
.italic {
    font-style: italic
}
.foto {
    background: url(/img/icons.png) 0 -664px no-repeat
}
.video {
    background: url(/img/icons.png) 0 -470px no-repeat
}
.blog {
    background: url(/img/icons.png) 0 -902px no-repeat
}

.nav__category {
    text-align: right;
    padding-right: 12px;
    font-size: 16px;
    color: #fff;
    margin-top: -4px
}

.banner.desktop-side-banner
{
	display: block;
	position: absolute;
	height: auto;
	width: auto;
	/*border: 1px solid gray;*/
	border: 0;
	max-width: 200px;
}
.banner.desktop-side-banner ins
{
	border: 0;
}
.banner.desktop-side-banner.desktop-side-banner-left
{
	top: 263px;
	left: 10px;
}
.banner.desktop-side-banner.desktop-side-banner-right
{
	top: 263px;
	right: 10px;
}

@media only screen and (max-width: 1455px)
{
	.banner.desktop-side-banner
	{
		display: none;
	}
}

@media only screen and (max-width: 800px) {
    .footer .footer__in,
    .nav .nav__mainin,
    .nav_top .nav_top__wrap,
    .header__wrap,
    .page {
        width: 95%;
        margin: 0 auto
    }

    .l
    {
        margin: 0 auto;
    }

    .l .l-col,
    .l .l-col1,
    .l .l-col2,
    .l .l-col3 {
        float: none;
        width: 100%;
        margin: 0 auto
    }

    .header .header__left,
    .header .header__right {
        display: none;
    }

    .header {
        width: 100%;
        padding: 0px 0px;
        border-bottom: 1px solid #ededed;
    }

    .header__wrap {
        padding: 15px 0px;
        position: relative;
    }

    .header .header__logo {
        display: none;
    }

    .header .header__logo__mobile {
        display: table;
        margin: 0px auto;
    }

    .header .header__logo__mobile img {
        vertical-align: middle;
    }

    .header .header__auth {
        top: 7px;
    }

    .mobile-soc__button
    {
        display: block;
    }

    .mobile-menu__button {
        display: block;
    }

    .nav_top {
        height: 46px;
        line-height: 46px;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

    }

    .nav_top::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .nav_top::-webkit-scrollbar {
        background-color: transparent;
    }

    .nav_top::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .nav_top__left-gradient,
    .nav_top__right-gradient {
        display: block;
    }

    .nav_top::-webkit-scrollbar {
        display: none; 
    }

    .nav_top .nav_top__special a {
        margin-right: 24px;
    }

    .nav_top .nav_top__special a.is-active {
        color: #ffb15e;
        text-decoration: underline;
    }

    .nav_top .nav_top__special .archive-link {
        display: inline-block;
    }
    
    .nav_top .search {
        display: none;
    }

    .nav .nav__sub {
        height: auto
    }
/*
    .slider .slider__slide {
        width: 100%
    }
    .slider .slider__img, .slider .slider__img img
    {
         width: 100%;
    }
*/
    .slider .slider__img .clip
    {
        height: unset;
        width: unset;
        min-width: 500px;
        width: 130%;
    }
/*
    .also__post
    {
        width: 95%;
    }
    .also__post .also__img, 
    .also__post .also__img .clip, 
    .also__post .also__img .clip img
    {
        width: 100%;
    }
    .also__img .clip
    {
        left: unset;
        top: -25%;
    }
*/
    .banner, .hbnhab {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .banner ins, .hbnhab ins
    {
        margin: 0 auto;
    }
    .banner img, .hbnhab img {
        width: 100%!important
    }
    .author {
        padding-right: 0;
        width: 50%;
        height: 150px;
    }
    .footer .footer__left,
    .footer .footer__right {
        float: none
    }
    .footer .footer__right {
        margin-bottom: 15px;
    }

    .footer .footer__nav,
    .footer .footer__left, 
    .footer .footer__right {
        margin-left: 0px;
        margin-right: 0px;
        width: auto;
    }
    .footer .footer__counters {
        padding-bottom: 20px
    }
    .footer .footer__counters img {
        padding-bottom: 5px
    }
    .footer .footer__nav li a {
        margin-bottom: 5px
    }
    .month {
        width: 47%
    }
    .month .day {
        height: 5%
    }
    .also__post {
        width: 100%;
        padding: 10px;
        overflow: hidden
    }
    .authors__list__item .author__pic {
        width: 100%
    }
    .authors__list__item .author__info {
        float: left;
        width: 100%
    }
    .spec__top {
        float: left;
        width: 100%;
        height: auto
    }
    .top #ticker {
        height: 34px;
        width: 95%
    }
    .top #ticker ul li {
        height: 34px
    }
    .paginate-block.centered {
        overflow: hidden;
        margin: 0 auto
    }
    .nav__main,
    .nav__sub {
        display: none
    }
}
@media only screen and (max-width: 650px) {
    .paginate-block.centered {
        width: 100%;
        overflow: hidden;
        margin: 0 auto
    }
}
@media only screen and (max-width: 560px) {

    .nav_top .nav_top__special li:first-child a {
        margin-left: 13px;
    }


    .nav .nav__mainin {
        margin-left: 12px
    }
    .nav .nav__mainin a {
        font-size: 14px;
        padding: 0 15px
    }
    .nav .nav__main ul {
        list-style: none;
        font-size: 0;
        position: relative
    }
    .article .article__author dt {
        float: none;
        display: block
    }
    .author .author__pic {
        float: none;
        margin-bottom: 5px
    }
    .interesting__more a {
        font-size: 12px
    }
    .footer .footer__nav li {
        display: block
    }
    .month {
        width: 100%
    }
    .top #ticker ul li {
        height: 51px
    }
    .paginate-block.centered {
        width: 100%;
        overflow: hidden;
        margin: 0 auto
    }
}

@media only screen and (max-width: 414px) 
{
	.breadcrumbs
	{
		display: none;
	}
}

.article .article__summary .article__img__big img {
    object-position: center;
    height: auto;
    width: auto;
    max-height: 250px;
    max-width: 300px
}

/*------- cust. newdev -------*/

.dossier__list
{
	display: flex;
	flex-wrap: wrap;
}

.dossier__list__item
{
	margin-bottom: 20px;
	width: 100%;
	float: left;
	overflow: hidden;
}

.dossier__list__item .dossier__title
{
	font-size: 15px;
	font-weight: 700;
	color: #343739;
	line-height: 1.1;
	margin-bottom: 7px;
}

.dossier__list__item .dossier__title a
{
	text-decoration: none;
	color: #383a3c;
}

.dossier__list__item .dossier__pic
{
	float: left;
	width: 164px;
	padding-bottom: 50px;
}

.dossier__list__item .dossier__info
{
	float: right;
	width: 509px;
	font-size: 14px;
	max-width: 100%;
}

.dossier-content
{
	min-height: 300px;
}

.dossier__filter
{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin-bottom: 30px;
}

.dossier-letter-filter-block
{
	display: flex;
	flex-wrap: wrap;
}

.dossier-letter-filter-block a
{
	margin: 0 2px;
	text-decoration: navajowhite;
}

.dossier-reset-letter-filter-link
{
	margin: 0 2px;
	text-transform: uppercase;
	line-height: 1;
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 580px)
{
	.article.dossier .article__summary .article__img__big img
	{
		max-height: 300px;
		width: auto;
		margin: auto;
		display: block;
	}
}

.atcpromoicon
{
	background-image: url('/promo.png');
	width: 39px;
	height: 25px;
	background-size: cover;
	background-repeat: no-repeat;
}


@media only screen and (max-width: 580px) 
{
	.article__img__big
	{
		width: 100%;
		margin: 0 !important;
	}
	
	.article__img__big
	{
		width: 100%;
		margin: 0;
	}
	
	.article .article__summary .article__img__big img
	{
		width: 100%;
		max-width: 100%;
		max-height: initial;
	}
}

/*------- cust. multitabs block -------*/

.multitabs-block
{
	padding-bottom: 25px;
}
.mtab
{
	padding: 5px 10px;
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	border: 0;
	border-bottom: 1px solid #d9d9d9;0;
	color: #fff;
	background: #637079;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	border-radius: 3px 3px 0 0;
}
.mtab.mtab-active
{
	color: #d88934;
	text-decoration: underline;
	cursor: default;
	border: 1px solid #d9d9d9;
	border-bottom: 0;
	
	padding-left: 5px;
	background: transparent;
}
.multitabs-tabs
{
	display: flex;
}
.multitabs-content
{
	position: relative;
	padding: 15px 0;
}
.mtab-content
{
	display: none;
}

.mtab-content.mtab-content-active
{
	display: block;
}

/* -------- */
.adsbygoogle
{
	clear: both;
}

/*------- cust. lang popup -------*/

.lang-switch-popup-wrap
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #a7a7a78f;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	transition: all 0.2s ease-in;
	opacity: 0;
}

.lang-switch-popup-wrap.shown
{
	opacity: 1;
	pointer-events: all;
}

.lang-switch-popup
{
	width: 450px;
	max-width: 75%;
	margin: auto;
	background-color: #FCFCFC;
	padding: 25px;
	border-radius: 8px;
	position: relative;
	transition: all 0.2s ease-in;
	transform: scale(0);
	opacity: 0;
	box-shadow: 0 0 1px 1px #ffb71e;
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lang-switch-popup-wrap.shown .lang-switch-popup
{
	transition: all 0.2s ease-in;
	transform: scale(1);
	opacity: 1;
}

.lang-switch-popup-logo
{
	width: 20%;
	overflow: hidden;
	min-width: 100px;
}

.lang-switch-popup-logo img
{
	width: 100%;
}

.lang-switch-popup-content
{
	font-size: 20px;
	text-align: center;
}

.lang-switch-popup-close
{
	position: absolute;
	top: 3px;
	right: 7px;
	font-size: 18px;
	cursor: pointer;
	text-shadow: 1px 1px 2px #7e7272;
	color: #4b4b4b;
}

@media (max-width: 767px) 
{
	.lang-switch-popup
	{
		margin-top: 100px;
	}
}